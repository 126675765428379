import { Component, OnInit } from '@angular/core';
import { ConfigService } from './shared/services';

/**
 * Sitemap component for use with siteimprove. Not linked to anywhere else in the app
 */
@Component({
    selector: 'sitemap',
    template: `
        <ng-container *ngFor="let url of urls">
            <a [href]="url" [innerHTML]="url"></a>
            <br />
        </ng-container>
    `,
})
export class SitemapComponent implements OnInit {
    urls: Array<string> = [];

    constructor(private configService: ConfigService) {}

    ngOnInit(): void {
        const urls: Array<string> = this.configService.getPageConfig('sitemap');

        if (urls && urls.length > 0) {
            this.urls = urls.map((url) => `${window.location.origin}/shopping/${url}`);
        }
    }
}
