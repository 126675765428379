import { animation, trigger, state, style, animate, transition, useAnimation } from '@angular/animations';

const fadeIn = animation([style({ opacity: 0 }), animate('500ms ease', style({ opacity: 1 }))]);

const fadeOut = animation([animate('500ms ease', style({ opacity: 0 }))]);

const fadeInSlideDown = animation([
    style({ opacity: 0, transform: 'translateY(-1rem)' }),
    animate('500ms ease', style({ opacity: 1, transform: 'translateY(0)' })),
]);

const fadeOutSlideUp = animation([animate('500ms ease', style({ opacity: 0, transform: 'translateY(-1rem)' }))]);

export const fade = trigger('fade', [transition(':enter', [useAnimation(fadeIn)]), transition(':leave', [useAnimation(fadeOut)])]);

export const fadeInDown = trigger('fadeInDown', [
    transition(':enter', [useAnimation(fadeInSlideDown)]),
    transition(':leave', [useAnimation(fadeOutSlideUp)]),
]);
