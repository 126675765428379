import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fade } from '../../animations';
import { ConfirmationModalConfig } from '../confirmation-modal/confirmation-modal.component';

@Component({
    selector: 'confirmation-modal-wrapper',
    templateUrl: './confirmation-modal-wrapper.component.html',
    styleUrls: ['./confirmation-modal-wrapper.component.scss'],
    animations: [fade],
})
export class ConfirmationModalWrapperComponent {
    @Input() config: ConfirmationModalConfig = <ConfirmationModalConfig>{};
    @Input() context = <any>{};
    @Output() cancel = new EventEmitter();
    @Output() confirm = new EventEmitter();

    constructor() {}

    onButtonClicked(e, linkConfig) {
        location.href = linkConfig.link;
    }
}
