import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { BROKER_PORTAL_URLS, SHOPPING_URLS } from './app-urls.constant';
import { SitemapComponent } from './sitemap.component';

export const routes: Route[] = [
    {
        path: SHOPPING_URLS.BASE,
        loadChildren: () => import('./shopping/shopping.module').then((m) => m.ShoppingModule),
        data: {
            pageName: SHOPPING_URLS.APP,
            indexRoute: SHOPPING_URLS.BASE,
        },
    },
    {
        path: BROKER_PORTAL_URLS.BASE,
        loadChildren: () => import('./broker-portal/broker-portal.module').then((m) => m.BrokerPortalModule),
        data: {
            pageName: BROKER_PORTAL_URLS.APP,
            indexRoute: BROKER_PORTAL_URLS.BASE,
        },
    },
    {
        path: 'icx',
        loadChildren: () => import('./icx/icx.module').then((m) => m.IcxModule),
        data: {
            pageName: 'global',
            indexRoute: 'icx',
        },
    },
    {
        path: 'sitemap',
        component: SitemapComponent,
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
