import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getValue, isEmptyObj, VALID_ROLES } from '@zipari/web-utils';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';

import { ConfigService } from './config.service';
import { LoggerService } from './logger.service';
import { authUserLoggedInUser } from '../../app.constants';

export class UserRoles {
    id: number;
    category: string;
    group: string;
    active: boolean;
    name: string;
    display_name: string;
}

export class AppUserData {
    accepted_terms?: any;
    agency_id?: string;
    agency_name?: null;
    broker_id?: string;

    /** generated on FE */
    broker_url?: string;

    cohort_keys?: [];
    create_timestamp?: string;
    cx_id?: string | number;
    data?: any;
    email_address?: string;
    email_verified?: boolean;
    first_name?: string;
    group_name?: string;
    groups?: Array<{ category: string; id: string | number; name: string }>;
    id?: number;
    identities?: any;
    is_active?: boolean;
    last_login?: string;
    last_name?: string;
    license_effective_date?: string;
    license_number?: string;
    member_id?: string | number;
    permissions?: any;
    phone_number?: string | number;
    prospect_id?: string | number;
    roles?: Array<UserRoles>;
    tenant_name?: string;
    update_timestamp?: string;
    user_name?: string;
}

export class User {
    app_user_data: AppUserData;
    roles?: Array<UserRoles>;
}

@Injectable()
export class AuthService {
    public replayPath: string;
    public selectedPlanIds: number[];
    public appConfig: any;
    // public userRole = 'Broker';
    public user: any;
    public actualUser: any;
    public impersonatedUser: any;
    private readonly forgotPasswordUrl = 'api/user/forgot_password/';
    private readonly resetPasswordUrl = 'user/reset_password/';
    private readonly changePasswordUrl = 'user/change-password/';
    private readonly defaultLogoutUrl = 'user/logout/';

    constructor(private http: HttpClient, private loggerService: LoggerService, private configService: ConfigService) {}

    private appUserData$ = new BehaviorSubject(null);

    public get appUserData(): Observable<any> {
        return this.appUserData$.asObservable();
    }

    public get loggedInUser(): User {
        return this.impersonatedUser ? this.impersonatedUser : this.actualUser;
    }

    public get userRole() {
        if (!this.loggedInUser || (this.loggedInUser && this.loggedInUser.roles && this.loggedInUser.roles.length === 0)) {
            // TODO let's change this to be a constant, not a string
            return 'Anonymous';
        }
        // TODO This has gotten over complicated.. cant this just be the this.user?
        if (this.loggedInUser.app_user_data && this.loggedInUser.app_user_data.data && this.loggedInUser.app_user_data.data.active_role) {
            return this.loggedInUser.app_user_data.data.active_role;
        }
        const validRoles = this.loggedInUser.roles.filter((role) => {
            return VALID_ROLES.includes(role.name);
        });
        if (validRoles.length < 1) {
            this.loggerService.warn('No Valid Roles for Logged in User');
        }
        return validRoles[0]?.name;
    }

    public get roleCount() {
        return this.loggedInUser
            ? this.loggedInUser.roles.filter((role) => {
                  return VALID_ROLES.includes(role.name);
              }).length
            : 0;
    }

    public get isBroker() {
        return !!getValue(this.user, 'broker_id');
    }

    get externalLoginUrl(): string {
        const globalConfig: any = this.appConfig.global;
        const externalLogin: string = globalConfig.externalLogin;

        // safe check because environment variables currently can't be changed to empty string
        if (externalLogin && externalLogin.indexOf('http') >= 0) {
            return externalLogin;
        }

        return '';
    }

    get externalRegisterUrl(): string {
        const globalConfig: any = this.appConfig.global;
        const externalRegister: string = globalConfig.externalRegister;

        // safe check because environment variables currently can't be changed to empty string
        if (externalRegister && externalRegister.indexOf('http') >= 0) {
            return externalRegister;
        }

        return '';
    }

    _state_id;

    get state_id() {
        return this._state_id ? this._state_id : '';
    }

    login(payload: { username: string; password: string }): Observable<any> {
        let loginEndpoint: string = getValue(this.appConfig, 'login.loginEndpoint') || '/login/';
        const setActionForStateQP: string = this.appConfig.global.setActionForStateQP;

        if (loginEndpoint && this.state_id && setActionForStateQP) {
            loginEndpoint = `${loginEndpoint}${setActionForStateQP}state=${this.state_id}`;
        } else if (loginEndpoint && this.state_id) {
            const loginHasQP: boolean = loginEndpoint.includes('&');
            loginHasQP
                ? (loginEndpoint = `${loginEndpoint}&state=${this.state_id}`)
                : (loginEndpoint = `${loginEndpoint}?state=${this.state_id}`);
        }

        return this.http.post<any>(loginEndpoint, payload).pipe(
            tap((response) => {
                this.appUserData$.next(response);
            })
        );
    }

    logout() {
        return this.http.post<any>('api/user/logout/', {});
    }

    register(payload: any): Observable<any> {
        let registerEndpoint: string = '/user/register/';

        if (registerEndpoint && this.state_id) {
            const registerHasQP: boolean = registerEndpoint.includes('&');

            registerHasQP
                ? (registerEndpoint = `${registerEndpoint}&state=${this.state_id}`)
                : (registerEndpoint = `${registerEndpoint}?state=${this.state_id}`);
        }

        return this.http.post(registerEndpoint, payload);
    }

    getUser(dataSource): Promise<any> {
        if (dataSource.userDataSource === 'init_data') {
            return this.getUserFromInitData();
        } else {
            return this.getUserFromApi();
        }
    }

    getUserFromApi() {
        return new Promise((resolve, reject) => {
            this.http
                .get<any>('api/user/')
                .toPromise()
                .then((data) => {
                    sessionStorage.setItem(authUserLoggedInUser.appUserData, JSON.stringify(data));
                    this.appUserData$.next(data);
                    resolve(data);
                })
                .catch((data) => {
                    this.actualUser = null;
                    this.user = {};
                    if (data.status === 403 || data.status === 401) {
                        return resolve(null);
                    }
                    reject(data);
                });
        });
    }

    setAppConfig(config) {
        this.appConfig = config;
    }

    setLoggedInUser(data, config?) {
        if (isEmptyObj(data)) {
            return;
        }

        if (!config || (config && config.userDataSource !== 'init_data')) {
            // Anon hacked together need to rework it to make it cleaner
            if (data) {
                this.actualUser = {
                    app_user_data: data,
                    roles: data.roles,
                };
            }

            this.setLoggedInUserData(data);
            this.user = data;
        }
    }

    getUserFromInitData() {
        const promise = this.http.get<any>('init_data').toPromise();
        const enrollmentUserPromise = this.http.get<any>('/api/user/').toPromise();

        enrollmentUserPromise.then((enrollUserData) => {
            this.user = enrollUserData.impersonated_user ? enrollUserData.impersonated_user : enrollUserData;
        });
        promise.then((data) => {
            this.appUserData$.next(data);
            this.actualUser = data.USER_INFO;
            this.impersonatedUser = data.USER_INFO.impersonated_user;
        });

        return Promise.all([enrollmentUserPromise, promise]).then();
    }

    setUserRole(role) {
        const data = { data: { active_role: role } };

        return new Promise((resolve) => {
            this.http.put(`api/user/`, data).subscribe(() => {
                this.getUserFromApi().then(() => {
                    resolve(null);
                });
            });
        });
    }

    /**
     * set user data
     * @param data user.data json-blob
     */
    setUserData(data: AppUserData) {
        return this.http.put(`api/user/`, { data }).subscribe((userData) => {
            this.appUserData$.next(userData);
        });
    }

    sendForgotPasswordEmail(payload): Observable<any> {
        const apiEndpoint = payload.endpoint ? payload.endpoint : this.forgotPasswordUrl;
        if (apiEndpoint === this.forgotPasswordUrl) {
            delete payload.endpoint;
            delete payload.next;
            delete payload.username;
        }
        return this.http.post<string>(apiEndpoint, payload);
        // TODO: want to pass `next` URL in JSON, but backend refusing ATM
        // return this.http.post<string>(this.forgotPasswordUrl, payload);
    }

    resetPassword(payload: { user_name: string; password: string; confirm_password: string; token: string }): Observable<any> {
        return this.http.post<string>(this.resetPasswordUrl, payload);
    }

    changePassword(payload: { current_password: string; password: string; confirm_password: string }): Observable<any> {
        const formattedPayload = {
            success_url: window.location.href,
            current_password: payload.current_password,
            new_password: payload.password,
            confirm_new_password: payload.confirm_password,
        };

        return this.http.post<string>(this.changePasswordUrl, formattedPayload);
    }

    updateUserData(data: any): Observable<any> {
        return this.http.put('api/user/', { data }).pipe(
            tap((userData) => {
                this.setLoggedInUser(userData);
            })
        );
    }

    handleExternalRegisterOrLogin() {
        if (this.externalRegisterUrl) {
            this.handleExternalRegister();
        } else {
            this.handleExternalLogin();
        }
    }

    handleExternalRegister() {
        let externalRegister: string = this.externalRegisterUrl;

        if (externalRegister && this.state_id) {
            const externalLoginHasQP: boolean = externalRegister.includes('&');

            externalLoginHasQP
                ? (externalRegister = `${externalRegister}&state=${this.state_id}`)
                : (externalRegister = `${externalRegister}?state=${this.state_id}`);
        }

        if (externalRegister) window.location.assign(externalRegister);
    }

    handleExternalLogin() {
        let externalLogin: string = this.externalLoginUrl;
        const setActionForStateQP: string = this.appConfig.global.setActionForStateQP;

        const logsEnabled: boolean = !!this.appConfig?.global?.logsEnabled;
        if (logsEnabled) {
            this.loggerService.logGroup([externalLogin, setActionForStateQP, this.state_id], 'handleExternalLogin');
        }

        if (externalLogin && this.state_id && setActionForStateQP) {
            externalLogin = `${externalLogin}${setActionForStateQP}state=${this.state_id}`;
            window.location.assign(externalLogin);
        } else if (externalLogin && this.state_id) {
            const externalLoginHasQP: boolean = externalLogin.includes('&');
            externalLoginHasQP
                ? (externalLogin = `${externalLogin}&state=${this.state_id}`)
                : (externalLogin = `${externalLogin}?state=${this.state_id}`);

            window.location.assign(externalLogin);
        } else if (externalLogin) {
            window.location.assign(externalLogin);
        }
    }

    handleLogout(next = '') {
        const logoutUrl = getValue(this.appConfig, 'global.logoutUrl') || this.defaultLogoutUrl;
        const doLogoutPost = getValue(this.appConfig, 'global.doLogoutPost');
        const postRevokeUrl = getValue(this.appConfig, 'global.postRevokeUrl');
        const externalLogout = getValue(this.appConfig, 'global.externalLogout');
        const nextUrl = next || `${window.location.origin}/${this.configService.appRoute}/login`;

        if (doLogoutPost) {
            this.http
                .post(logoutUrl, {})
                .pipe(
                    finalize(() => {
                        window.location.assign(postRevokeUrl);
                    })
                )
                .subscribe();
        } else if (externalLogout) {
            window.location.assign(`/logout?next=${externalLogout}`);
        } else {
            window.location.assign(`/logout?next=${nextUrl}`);
        }
    }

    private setLoggedInUserData(appUserData: AppUserData): void {
        const appData = JSON.parse(sessionStorage.getItem(authUserLoggedInUser.appUserData)) || appUserData;

        this.appUserData$.next(appData);
    }
}
