export enum EnrollmentSteps {
    'applicant_information' = 'Applicant Information',
    'medicare_eligibility' = 'Medicare Eligibility',
    'health_evaluation' = 'Health Evaluation',
    'medical_history' = 'Medical History',
    'medical_history_details' = 'Medical History Details',
    'important_questions' = 'Important Questions',
    'past_current_coverage' = 'Past & Current Coverage',
    'payment' = 'Payment Information',
    'review' = 'Review Application',
    'attestations' = 'Authorization & Signature',
    'confirmation' = 'Application Submission Confirmation',
}

export enum EnrollmentStepKeys {
    applicant_information = 'applicant_information',
    medicare_eligibility = 'medicare_eligibility',
    health_evaluation = 'health_evaluation',
    medical_history = 'medical_history',
    medical_history_details = 'medical_history_details',
    important_questions = 'important_questions',
    past_current_coverage = 'past_current_coverage',
    payment = 'payment',
    pcc_selection = 'pcc_selection',
    provider_search = 'provider_search',
    review = 'review',
    attestations = 'attestations',
    confirmation = 'confirmation',
    dental_vision = 'dental_vision',
}

export enum SigneeTypes {
    'self' = 'enrollee',
    'enrollee' = 'enrollee',
    'broker' = 'broker',
    'sales_rep' = 'sales_rep',
    'authorized_representative' = 'authorized_person',
    'authorized_person' = 'authorized_person',
}

export enum ContactTypes {
    authorized_person = 'authorized_person',
    emergency_contact = 'emergency_contact',
    power_of_attorney = 'power_of_attorney',
    authorized_representative = 'authorized_representative',
}

export enum countyKeys {
    demographicsCounty = 'demographics.county',
    county = 'county',
    workflowValuesDemographicsCounty = 'workflow.values.demographics.county',
}

export enum zipCodeKeys {
    demographicsZipcode = 'demographics.zipcode',
    countyZipCode = 'county.zipcode',
    getStartedZipcode = 'getStarted.zipcode',
}

export const countyCode = 'county_code';

export const fipsCountyCode = 'fips_county_code';

export const MedicareApplicantConstant = {
    preferred_language_for_info: 'preferred_language_for_info',
    preferred_accessible_format: 'preferred_accessible_format',
    not_answered: 'N',
};

export const USER_ROLES = {
    BROKER: 'broker',
    CONSUMER: 'consumer',
};

export interface AttributeConfig {
    label: string;
    value: string;
    icon?: string;
    id?: string;
    format?: string;
    noValueMessage?: string;
}

export interface ActionConfig {
    label: string;
    icon?: string;
    action: string;
    route?: string;
}

export interface AsideConfig {
    header: string;
    label: string;
    attributes: AttributeConfig[];
    extraAttributes?: AttributeConfig[];
    brokerAttributes?: AttributeConfig[];
    actions?: ActionConfig[];
    toggle?: object;
    shareQuoteButton?: {
        allowedStatuses: string[];
    };
}

export interface SectionConfig {
    label: string;
    type: string;
}

export interface BadgeConfig {
    endpoint?: string;
    label: string;
    type: string;
    value?: string | number;
    format?: string;
}

export interface BreadcrumbConfig {
    text: string;
    route: string;
}

export interface ClientData {
    address_1?: string;
    address_2?: string;
    agency: string;
    agency_name: string;
    broker: string;
    broker_name: string;
    city: string;
    clientType?: string;
    created_at: string;
    effective_date: string;
    email: string;
    email_address: string;
    external_lead_id: number;
    first_name: string;
    group: string[];
    home_phone: string;
    id: number;
    last_name: string;
    locked: boolean;
    market_segment: string;
    middle_initial?: string;
    notes: string;
    phone_number: string;
    state: string;
    status: string[];
    street_name_1?: string;
    street_name_2?: string;
    tenant: string;
    termination_date: string;
    updated_at: string;
    webuser_id: string;
    zipcode?: string;
    zip_code?: string;
    zipCode?: string;
}

export interface GlobalConfig {
    ede?: boolean;
    timeout: object;
    analytics: {
        cx_capture: {
            endpoint: string;
            source_system_name: string;
            mapping: {
                virtualPageView: string;
            };
        };
    };
    baseUrl: string;
    handleAuth: boolean;
    tenantName: string;
    title: string;
    payment: {
        vendor: string;
        ProcessingRegionCode: number;
        fiserve_endpoint: string;
    };
    plan_card?: {
        badges: {
            enabled: boolean;
        };
    };
    preventFamilyHighlightAttributes?: boolean;
    preserveRoutingWhenUnauthenticatedFromRoutes: string[];
    navigation?: object;
    formControl?: {
        toggle: {
            strictAll: boolean;
            strictIfRequired: boolean;
        };
        companyAddress: {
            populateCity: boolean;
        };
    };
    contentSkip?: {
        target: string;
        buttonConfig: {
            content: string;
            level: string;
        };
    };
    featuresFlag?: {
        overviewAsideOnDetail: {
            hasBrokerDetails: boolean;
        };
    };
}

export const ADULT_AGE = '18';

export const medicareBrokerPortalAppName = 'broker-portal-medicare';
