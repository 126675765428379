export const MARKET_SEGMENTS = {
    INDIVIDUAL: 'individual',
    MEDICARE: 'medicare',
    SMALL_GROUP: 'small-group',
    LARGE_GROUP: 'large-group',
};

export const GLOBAL_URLS = {
    AUTHORIZATIONS: 'authorizations',
    CLAIMS: 'claims',
    CONFIG_MANAGER: 'config-manager',
    DASHBOARD: 'dashboard',
    DOCUMENTS: 'documents',
    LOGIN: 'login',
    MESSAGES: 'messages',
    NOTIFICATIONS: 'notifications',
    REGISTER: 'register',
    PROFILE: 'profile',
    SETTINGS: 'settings',
    FORGOT_PASSWORD: 'forgot-password',
    REPORTS: 'reports',
    RESET_PASSWORD: 'reset-password',
    SESSION_EXPIRED: 'session-expired',
    SUPPORT: 'support',
    WORKBENCH: 'workbench',
    RESOURCES: 'resources',
    MARKET_SELECTION: 'market-selection',
    ERROR: 'error',
    QUOTE_ERROR: 'quote-error',
    APPLICATION_ERROR: 'application-error',
    TOKEN_ERROR: 'token-error',
};

export const BROKER_PORTAL_URLS = {
    APP: 'broker-portal',
    BASE: 'broker-portal',
    URLS: {
        AGENCIES: 'agencies',
        APPLICATIONS: 'applications',
        BROKERS: 'brokers',
        CLIENTS: 'clients',
        LEADS: 'leads',
        COMMISSIONS: 'commissions',
        CONTACTS: 'contacts',
        ENROLLMENT: 'enrollment',
        PREENROLLMENT: 'get-started',
        GROUPS: 'groups',
        MEMBERS: 'members',
        POLICIES: 'policies',
        PROSPECTS: 'prospects',
        QUOTES: 'quotes',
        QUOTES_UNAUTHORIZED: 'quotes-ua',
        CREATE_QUOTE: 'create-quote',
        RENEWALS: 'renewals',
        DOCUMENT_UPLOAD: 'document-upload',
    },
};

export const EMPLOYER_PORTAL_URLS = {
    APP: 'employer-portal',
    BASE: 'employer-portal',
    URLS: {
        EMPLOYEES: 'employees',
        CLAIMS: 'claims',
        BILLING: 'billing',
        REPORTS: 'reports',
        DOCUMENTS: 'documents',
    },
};

export const SHOPPING_URLS = {
    APP: 'zshop',
    BASE: 'shopping',
    URLS: {
        BILLING: 'billing',
        LANDING: 'landing',
        APPLICATIONS: 'applications',
        WORKBENCH: 'workbench',
        RENEWAL: 'renewal',
        UPSELL: 'upsell',
        CREATE_QUOTE: 'create-quote',
        QUOTES: 'quotes',
        ENROLLMENT: 'enrollment',
        PREENROLLMENT: 'get-started',
        POLICIES: 'policies',
        CHANGE_COVERAGE: 'change-coverage',
        DOCUMENT_UPLOAD: 'document-upload',
    },
};

export const SHP_BRK_SHARED_URLS = {
    APPLICATIONS: 'applications',
};

export const applicationsURLS = {
    brokerPortalEndpoint: 'api/broker-portal/applications/individuals/',
    shoppingEndpoint: 'api/shopping/applications/individuals/',
};

export const quotesURLS = {
    brokerPortalEndpoint: 'api/broker-portal/quotes/individuals/',
    shoppingEndpoint: 'api/shopping/quotes/individuals/',
};

export const clientsURLS = {
    brokerPortalEndpoint: 'api/broker-portal/clients/',
    shoppingEndpoint: 'api/shopping/clients/',
};
